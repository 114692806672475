// Mixin
import { mapActions, mapState } from "vuex";
import _ from "lodash";
const loadingMixin = {
  methods: {
    ...mapActions(["setLoading"]),
    ...mapActions("clientAuth", ["checkSession"]),
    statusColor(status = "success") {
      switch (status) {
        case "success":
        case "paid":
          return "#28a745";
        case "pending":
          return "#ffc107";
        case "cancelled":
        case "rejected":
          return "#f50";
        case "processed":
          return "#108ee9";
        default:
          return "";
      }
    },
    statusText(status = this.labels.table.columns.status.success) {
      return this.labels.table.columns?.status[status] ?? " - ";
    },
    normalizeNumbers(numbers) {
      if (!numbers) return numbers;
      return numbers
        .split("-")
        .map((number) => number.trim().padStart(2, "0"))
        .join("-");
    },
  },
  computed: {
    ...mapState("layout", ["screenWidth"]),
    isMobile() {
      return this.screenWidth < 768;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.setLoading(false);
    }, 500);
  },
  filters: {
    ucwords(value) {
      if (!value) return "";
      value = value.toString();
      return value
        .split(" ")
        .map((item) => _.capitalize(item))
        .join(" ");
    },
  },
};

export default loadingMixin;
