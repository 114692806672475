<template>
  <div class="page-404">
    <font-awesome-icon
      :icon="['far', 'face-frown']"
      size="5x"
      class="text-danger"
    />
    <h1>{{ labels.code }}</h1>
    <p>{{ labels.title }}</p>
    <router-link class="btn-home" :to="{ name: 'home' }">{{
      labels.goToHome
    }}</router-link>
  </div>
</template>
<script>
import loadingMixin from "@/mixins/loadingMixin";
import labels from "@/utils/labels";
import { pageTitle } from "../utils/utils";
export default {
  name: "NotFound",
  mixins: [loadingMixin],
  metaInfo: {
    title: pageTitle(labels[404].code),
    meta: [
      {
        name: "title",
        content: labels[404].code,
      },
      {
        name: "description",
        content: labels[404].title,
      },
    ],
  },
  data() {
    return {
      labels: labels[404],
    };
  },
};
</script>
<style lang="scss" scoped>
@import "~@/assets/scss/variable";
.page-404 {
  text-align: center;
  padding: 20px;
  min-height: 100vh;
  width: 100%;
  background-color: color(c-primary);
  color: color(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Exo 2", sans-serif;
  user-select: none;
  h1 {
    font-size: 3.5rem;
    margin-bottom: 20px;
    color: color(c-hover);
    margin-bottom: 0;
  }
  p {
    font-size: 1.3rem;
    margin-bottom: 20px;
  }
  .btn-home {
    background-color: transparent;
    border: 1px solid color(c-hover);
    color: color(c-hover);
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 0.85rem;
    margin-top: 20px;
    transition: all 0.5s ease;
    font-weight: 600;
    &:hover {
      background-color: color(c-hover);
      color: color(c-primary);
      transition: all 0.5s ease;
    }
  }
  .fa-face-frown {
    margin-bottom: 10px;
    color: color(c-hover);
    font-weight: 400;
  }
}
</style>
